import React, { useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import * as styles from '../../pages/styles/contact-page.module.css'
import Button from '../../components/button'
import { getTextCopy } from '../../helpers'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

/*
  NOTE: If you update the form fields, you must also update the shadow form
  on the /thanks/ page to prevent processing issus with Netlify
*/

const ContactForm = ({ sessionUrl }) => {
  const data = useStaticQuery(graphql`
    {
      contactFormComponent: contentfulComponent(
        name: { eq: "component.contactForm" }
      ) {
        ...componentFields
      }
      thanksPage: contentfulPage(name: { eq: "page.thanks" }) {
        thanksPageSlug: slug
      }
    }
  `)
  const { content } = data.contactFormComponent
  const { thanksPageSlug } = data.thanksPage

  const [disable, setDisable] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const verifySubmission = async () => {
    if (!executeRecaptcha) {
      return
    }

    setDisable(true)

    try {
      const contactForm = document.getElementById('contactForm')
      const formData = new FormData(contactForm)

      const token = await executeRecaptcha('contact')

      await fetch('/.netlify/functions/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
        }),
      })
        .then(response => {
          // @todo should we do something else in this case of 500 errors?
          // @todo temporary send to a separate form bucket to make sure we aren't being too aggressive with threshold
          // also remove the form from netlify and the thanks page when satisfied
          if (response.status === 500 || response.status === 400) {
            formData.set('form-name', 'recaptured-contact')
          }

          return response.json()
        })
        .then(data => formData.set('score', data.score))

      sendSubmission(formData)
    } catch (e) {
      console.log(e)
      setDisable(false)
    }
  }

  const sendSubmission = data => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(data).toString(),
    }).then(() => navigate(thanksPageSlug))
  }

  const handleSubmit = e => {
    e.preventDefault()
    verifySubmission()
  }

  return (
    <form
      id="contactForm"
      name="contact"
      method="POST"
      netlify-honeypot="verify"
      data-netlify="true"
      className={styles.formWrapper}
      onSubmit={handleSubmit}
      action={thanksPageSlug}
    >
      <h1 className={styles.heading}>
        {getTextCopy(content, 'component.contactForm.header')}
      </h1>
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="score" value="" />
      <input type="hidden" name="session" value={sessionUrl} />
      <label>
        {getTextCopy(content, 'component.contactForm.company')}
        <input type="text" name="company" />
      </label>
      <label>
        {getTextCopy(content, 'component.contactForm.name')}
        <input
          type="text"
          name="name"
          required
          pattern=".{3,}"
          title={getTextCopy(content, 'component.contactForm.nameError')}
        />
      </label>
      <label>
        {getTextCopy(content, 'component.contactForm.email')}
        <input
          type="email"
          name="email"
          required
          pattern=".{5,}"
          title={getTextCopy(content, 'component.contactForm.emailError')}
        />
      </label>
      <label>
        {getTextCopy(content, 'component.contactForm.phone')}
        <input
          type="tel"
          name="phone"
          required
          pattern=".{10,}"
          title={getTextCopy(content, 'component.contactForm.phoneError')}
        />
      </label>
      <label>
        {getTextCopy(content, 'component.contactForm.budget')}
        <input
          type="text"
          name="budget"
          required
          pattern=".{3,}"
          title={getTextCopy(content, 'component.contactForm.budgetError')}
        />
      </label>
      <label>
        {getTextCopy(content, 'component.contactForm.project')}
        <textarea name="project" required />
      </label>
      <p className={styles.verify}>
        <label>
          {getTextCopy(content, 'component.contactForm.verify')}
          <input type="text" name="verify" />
        </label>
      </p>
      <Button disabled={disable} action="primary" type="submit">
        {getTextCopy(content, 'component.contactForm.button')}
      </Button>
    </form>
  )
}

export default ContactForm
