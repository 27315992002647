import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import * as styles from './styles/contact-page.module.css'
import ContactForm from '../components/contactForm'
import Section from '../components/section'
import LocationMap from '../static/map.png'
import { getTextCopy, getHtmlCopy } from '../helpers'

const Contact = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    seoImage,
    pageSlug,
    content,
  } = data.contactPage

  const sessionUrl =
    typeof FS !== 'undefined' &&
    FS.getCurrentSessionURL() /* eslint-disable-line */

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        urlPath={pageSlug}
      />
      <Layout>
        <Section>
          <div className={styles.pageWrapper}>
            <ContactForm sessionUrl={sessionUrl} />
            <div className={styles.info}>
              <section>
                <h3>{getTextCopy(content, 'page.contact.hoursHeader')}</h3>
                <p>{getTextCopy(content, 'page.contact.hoursBody')}</p>
              </section>
              <section>
                <h3>{getTextCopy(content, 'page.contact.locationHeader')}</h3>
                <a
                  href={getTextCopy(content, 'page.contact.addressMapUrl')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getTextCopy(content, 'page.contact.address')}
                </a>
                <div
                  className={styles.image}
                  style={{ backgroundImage: `url(${LocationMap})` }}
                />
              </section>
              <section>
                <h3>{getTextCopy(content, 'page.contact.parkingHeader')}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getHtmlCopy(content, 'page.contact.parkingBody'),
                  }}
                />
                <a
                  href={getTextCopy(content, 'page.contact.parkingMapUrl')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.parkingLink}
                >
                  {getTextCopy(content, 'page.contact.parking')}
                </a>
              </section>
            </div>
          </div>
        </Section>
      </Layout>
    </>
  )
}

export default Contact

export const query = graphql`
  {
    contactPage: contentfulPage(name: { eq: "page.contact" }) {
      ...pageFields
    }
  }
`
